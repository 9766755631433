@import "sass-lib";
@media (min-width: 0px) and (max-width: 1480px) {
	.wrapper{ width: 1250px; padding: 0 60px; }
	header{
		height: 80px;
		.wrapper{ width:auto; margin: 0 58px;  }
		.logo{ 
			left: -58px; width: 365px;  bottom: 0; height: 80px;
			img{ height: 100%; width: 100%; }
		}
		.right-header{
			padding-top: 35px;
		 	nav{
		 		> ul{
		 			> li{
		 				margin: 0 20px 0 0; padding-bottom: 30px;
		 				&:last-child{ margin:0; }

		 				> a{ font-size: 14px; }
		 			}
		 		}
		 	}
		 	.box-search{
		 		.search-drop{ top: 65px; }
		 	}
		}

	}
	.banner-home{
		h1{ font-size: 49px; line-height: 55px; padding-right: 300px; }
		p{ margin: 20px 0 35px; padding-right: 300px; }
	}
	.home{
		h2{ font-size:31px; font-weight: 200; line-height: 40px;}
		h4{  }
		&.about{ 
			.wrapper{ padding: 0 0 0 60px; }
			h3{ font-size: 14px; line-height: 24px; }
		}
		&.register{
			.download-for{ width: 80%; }
			.right{
				h3{ font-size: 14px; line-height: 24px; }
			}
		}
		&.news{
			.news-wrap{
				.inner{
					p{ height: 75px; }
				}
			}
		}

	}
	.video-home{
		.thumb-video{ height: 380px; }
	}
	.banner-middle{
		h1{ font-size:49px; padding-right: 200px;  }
	}
	.middle{
		.linesec-medium{ left: -62px; }
	}

	.end-sec{ margin: 40px -1000px 60px -60px; }
	.two-box-middle{
		h2{ font-size: 28px; line-height: 34px; }
	}
	.middle{
		min-height: 250px;
		&.landing-detail{ padding-top: 160px; }
	}
	.input-form {
		.col {
			&.half {
				&.type-2 { 
		            width: 100%; 
		            input[type='text']{ padding: 20px; }
		        }
	        }
		}
	}

	.underconst{
		.logo {width: 240px; padding-top: 45px; margin-left: -50px;}   
		h1{ font-size:49px; line-height: 55px;  }
		h5{ margin-bottom: 5px; }
		&:after{
			/*width:311px; */
			width:435px; 
			background-size: 100%;
		}
		.wrapper{ width:1170px; padding:0 0 0 39px; margin: 0 40px; height: 100%;} 		
		.text{
			position: absolute; height: 50%; top: 0; bottom: 0; 
			margin: auto;
			padding-top: 20px; 
			/*margin-bottom: 50px;*/
			/*margin-right: 0px;*/
			.wrap-text{ 
				margin-top: 50px; margin-bottom: 40px; 
				/*padding: 0 130px 0 31px; */
				padding: 0 0 0 31px; 
				@include boxSizing(border-box);
			}
			figure{ 
				/*width: 30%;*/
				width: 285px; position: relative;
				text-align: center; margin: 0 auto;
				img{
					position: absolute; right: 0; top: 0;
				}
			}
		}
		.end-sec{ 
			/*margin:30px -1000px 30px -40px; */
			margin:30px 0 30px -40px; 
			position: absolute; bottom: 125px;
			width: 100%;			
		}		
		.line-body-sec{ left: -40px;}
		.linesec-medium{ left: -42px; }
		.tow-column{ 
			position: absolute; bottom: 0;
			padding-bottom: 40px; padding-left: 31px; 
		}
	}
	footer{
		.middle-footer{
			.wrapper{
				&:after{
					left:60px;
					right: 60px;
				}
			}
		}
	}

}


